import { UploadFile } from "antd/lib/upload/interface"

export const getFilesBase64 = (
  fileList: [UploadFile],
  sizeAsString?: boolean,
  isBinary?: boolean
) => {
  return Promise.all(
    fileList.map(
      file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          if (isBinary) {
            reader.readAsBinaryString(file.originFileObj as Blob)
          } else {
            reader.readAsDataURL(file.originFileObj as Blob)
          }
          reader.onload = e => {
            resolve({
              name: file.name,
              size: sizeAsString ? String(file.size) : file.size,
              type: file.type,
              content: e.target?.result
            })
          }
          reader.onerror = error => reject(error)
        })
    )
  )
}
