import React from "react"

import { useRequest } from "api/hooks"
import { MOSCOW_GMT, parseDateToBackNewConventions } from "constants/index"

import { ModalRadioButtonsProps, UsePenaltyRequests } from "./types"
import { RadioGroupComponent } from "Components/Radio"
import { FormItemNames } from "./AddEditPenaltyModal/helper"

export const SUPPORT_PENALTY_ADMIN_FILTER = "SUPPORT_PENALTY_ADMIN_FILTER"
export const TOTAL_COUNT_HEADER_KEY = "total"
const SUPPORT_PENALTY_ADMIN_BASE_URL_API = "v1/admin/penalty"
const SUPPORT_PENALTY_USER_DICT_URL_API = "v1/penalty/dict"

export const adminPenaltyText = {
  title: "Штрафы клубов",
  tooltip:
    "Раздел предназначен для оповещения и хранения информации о штрафах, наложенных в рамках штрафной сетки принятой в компании. Присутствует возможность поиска по номеру клуба, статусу штрафа и отображение штрафов только за необходимый период. Также раздел позволяет однократно оставить запрос на обжалование штрафа в течение 14 рабочих дней при условии веской аргументации для рассмотрения запроса.",
  period: "За период",
  addPenaltyButton: "Добавить штраф",
  filter: {
    statusesCheckBoxFilter: { header: "Статус штрафа" },
    searchInputFilter: {
      header: "Причина штрафа",
      placeholder: "Введите название"
    },
    rangePickerFilter: { header: "Период штрафа" }
  }
}

export const ModalRadioButtons: React.FC<ModalRadioButtonsProps> = ({
  value,
  onChange,
  options
}) => {
  return (
    <RadioGroupComponent
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
      options={options}
    />
  )
}

export const usePenaltyRequests = ({
  filterState,
  pagination,
  newProposalData,
  editModalData,
  cancelModalData,
  appealModalData,
  rejectFinalModalData,
  editFinalModalData
}: UsePenaltyRequests) => {
  const { search, ...restFilter } = filterState.data
  const { request: getPenaltyList } = useRequest({
    url: `${SUPPORT_PENALTY_ADMIN_BASE_URL_API}/list`,
    method: "POST",
    requestBody: {
      ...restFilter,
      dates:
        restFilter.dates?.from && restFilter.dates?.to
          ? parseDateToBackNewConventions({
              date: [restFilter.dates?.from, restFilter.dates?.to],
              gmt: MOSCOW_GMT,
              isObj: true
            })
          : undefined,
      search: !!search
        ? {
            field: FormItemNames.reason,
            value: search
          }
        : undefined,
      ...pagination
    }
  })

  const { request: newPenalty } = useRequest({
    url: SUPPORT_PENALTY_ADMIN_BASE_URL_API,
    method: "POST",
    requestBody: {
      ...newProposalData,
      penaltyValue: Number(newProposalData?.penaltyValue)
    }
  })

  const { request: getPenaltyDict } = useRequest({
    url: SUPPORT_PENALTY_USER_DICT_URL_API,
    method: "GET"
  })

  const { request: editPenalty } = useRequest({
    url: `${SUPPORT_PENALTY_ADMIN_BASE_URL_API}/${editModalData?.id}`,
    method: "PUT",
    requestBody: {
      ...editModalData,
      editPenaltyComment: editFinalModalData,
      penaltyValue: Number(editModalData?.penaltyValue)
    }
  })

  const { request: cancelPenalty } = useRequest({
    url: `${SUPPORT_PENALTY_ADMIN_BASE_URL_API}/${editModalData?.id}`,
    method: "PATCH",
    requestBody: {
      cancelComment: cancelModalData,
      status: 6
    }
  })

  const { request: rejectPenalty } = useRequest({
    url: `${SUPPORT_PENALTY_ADMIN_BASE_URL_API}/${editModalData?.id}`,
    method: "PATCH",
    requestBody: {
      cancelComment: rejectFinalModalData,
      status: 5
    }
  })

  const { request: appealPenalty } = useRequest({
    url: `${SUPPORT_PENALTY_ADMIN_BASE_URL_API}/${editModalData?.id}`,
    method: "PATCH",
    requestBody: {
      ...appealModalData,
      penaltyValueResolved: Number(appealModalData?.penaltyValueResolved),
      penaltyValue: undefined
    }
  })

  return {
    getPenaltyDict,
    getPenaltyList,
    newPenalty,
    editPenalty,
    cancelPenalty,
    appealPenalty,
    rejectPenalty
  }
}
