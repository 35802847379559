import React, { useCallback, useEffect, useState } from "react"
import { useRequestBody } from "api/hooks"
import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import PaginationComponent from "Components/Pagination"
import NotifiEmptyTable from "Containers/Tools/Notifications/Table/EmptyTable"
import { SUPPORT_PENALTY_ADMIN_FILTER } from "./helper"
import {
  adminPenaltyText,
  TOTAL_COUNT_HEADER_KEY,
  usePenaltyRequests
} from "./helper"
import {
  AdminPenaltyData,
  AdminPenaltyFilter,
  Dict,
  PenaltyDataListResponse,
  ReportDataState
} from "./types"
import { StatusesDict } from "../../Proposal/types"
import { ButtonPrimary } from "Components/Button"
import { AdminPenaltyCard } from "./AdminPenaltyCard/AdminPenaltyCard"
import AddEditPenaltyModal from "./AddEditPenaltyModal"
import { AddEditModalData } from "./AddEditPenaltyModal/types"
import ViewPenaltyModal from "./ViewPenaltyModal"
import { EditCancelRejectFinalModal } from "./EditCancelRejectFinalModal/EditCancelRejectFinalModal"
import { AppealModal } from "./AppealModal/AppealModal"
import moment from "moment"
import { getDateDescriptionLayout } from "constants/index"
import { AppealModalData } from "./AppealModal/types"
import {
  getInitialFilterState,
  statusReadableLabels,
  winEmpty
} from "../helpers"

const initialReportData: ReportDataState = {
  data: [],
  total: 0,
  pending: false
}

export const AdminPenalty: React.FC = () => {
  const [dict, setDict] = useState<Dict>({
    reasons: [],
    statuses: [],
    win: []
  })

  const { pagination, setPagination } = useRequestBody(20)
  const initFilterState = getInitialFilterState({
    storage: SUPPORT_PENALTY_ADMIN_FILTER,
    isAdmin: true
  })

  const [initDataRequested, setInitDataRequested] = useState(false)
  const [filterState, setFilterState] = useState<AdminPenaltyFilter>(
    initFilterState
  )
  const [reportData, setReportData] = useState<ReportDataState>(
    initialReportData
  )

  const [addPenaltyModal, setAddPenaltyModal] = useState(false)
  const [editPenaltyModal, setEditPenaltyModal] = useState(false)
  const [appealModal, setAppealModal] = useState(false)
  const [editFinalModal, setEditFinalModal] = useState(false)
  const [cancelFinalModal, setCancelFinalModal] = useState(false)
  const [rejectFinalModal, setRejectFinalModal] = useState(false)
  const [viewPenaltyModal, setViewPenaltyModal] = useState(false)
  const [viewModalData, setViewModalData] = useState<AdminPenaltyData | null>(
    null
  )
  const [editModalData, setEditModalData] = useState<AdminPenaltyData | null>(
    null
  )

  const [editFinalModalData, setEditFinalModalData] = useState("")
  const [cancelFinalModalData, setCancelFinalModalData] = useState("")
  const [rejectFinalModalData, setRejectFinalModalData] = useState("")

  const [
    addEditModalData,
    setAddEditModalData
  ] = useState<AddEditModalData | null>(null)

  const [
    appealModalData,
    setAppealModalData
  ] = useState<AppealModalData | null>(null)

  const {
    getPenaltyDict,
    getPenaltyList,
    newPenalty,
    editPenalty,
    cancelPenalty,
    appealPenalty,
    rejectPenalty
  } = usePenaltyRequests({
    filterState,
    pagination,
    newProposalData: addEditModalData,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    editModalData: {
      ...viewModalData,
      ...editModalData,
      ...addEditModalData,
      setDate: moment(editModalData?.setDate),
      findDate: moment(editModalData?.findDate)
    },
    cancelModalData: cancelFinalModalData,
    appealModalData: appealModalData,
    rejectFinalModalData,
    editFinalModalData
  })

  const setNextReportDataList = (response: PenaltyDataListResponse) => {
    setReportData(prev => ({
      ...prev,
      data: response.data,
      total: response.headers[TOTAL_COUNT_HEADER_KEY],
      pending: false
    }))
  }

  useEffect(() => {
    if (addEditModalData && addPenaltyModal && !editPenaltyModal) {
      setReportData(prev => ({ ...prev, pending: true }))
      newPenalty()
        .then(res => {
          setReportData(prev => ({
            ...prev,
            data: [res.data, ...prev.data],
            pending: false
          }))
          setAddPenaltyModal(false)
          resetPaginationCb()
        })
        .catch(() => {
          setReportData(prev => ({ ...prev, pending: false }))
        })
    }
  }, [addEditModalData, addPenaltyModal, editPenaltyModal])

  useEffect(() => {
    if (filterState.isActive || !initDataRequested) {
      setReportData(prev => ({ ...prev, pending: true }))
      getPenaltyList().then(resp => {
        setNextReportDataList(resp)
        setInitDataRequested(true)
      })
      setInitDataRequested(true)
    }
  }, [filterState])

  useEffect(() => {
    getPenaltyDict().then(res =>
      setDict({
        ...res.data,
        statuses: statusReadableLabels(res.data.statuses),
        win: [winEmpty, ...res.data.win]
      })
    )
  }, [])

  useEffect(() => {
    if (editFinalModal && editModalData && editFinalModalData) {
      setReportData(prev => ({ ...prev, pending: true }))
      editPenalty()
        .then(() => {
          getPenaltyList().then(res => {
            setNextReportDataList(res)
            setViewPenaltyModal(false)
            setEditFinalModal(false)
            setEditPenaltyModal(false)
            setReportData(prev => ({ ...prev, pending: false }))
          })
        })
        .finally(() => {
          setReportData(prev => ({ ...prev, pending: false }))
        })
    }
  }, [editFinalModalData, editModalData])

  useEffect(() => {
    if (cancelFinalModalData && cancelFinalModal && viewModalData) {
      setReportData(prev => ({ ...prev, pending: true }))
      cancelPenalty()
        .then(() => {
          getPenaltyList().then(res => {
            setNextReportDataList(res)
            setCancelFinalModal(false)
            setCancelFinalModalData("")
            setViewPenaltyModal(false)
            setViewModalData(null)
            setReportData(prev => ({ ...prev, pending: false }))
          })
        })
        .finally(() => {
          setReportData(prev => ({ ...prev, pending: false }))
        })
    }
  }, [cancelFinalModalData, editModalData])

  useEffect(() => {
    if (appealModalData) {
      setReportData(prev => ({ ...prev, pending: true }))
      appealPenalty()
        .then(() => {
          getPenaltyList().then(res => {
            setNextReportDataList(res)
            setAppealModal(false)
            setAppealModalData(null)
            setViewPenaltyModal(false)
            setViewModalData(null)
            setReportData(prev => ({ ...prev, pending: false }))
          })
        })
        .finally(() => {
          setReportData(prev => ({ ...prev, pending: false }))
        })
    }
  }, [appealModalData])

  useEffect(() => {
    if (rejectFinalModalData) {
      setReportData(prev => ({ ...prev, pending: true }))
      rejectPenalty()
        .then(() => {
          getPenaltyList().then(res => {
            setNextReportDataList(res)
            setRejectFinalModal(false)
            setViewPenaltyModal(false)
            setReportData(prev => ({ ...prev, pending: false }))
            setRejectFinalModalData("")
            setViewModalData(null)
          })
        })
        .finally(() => {
          setReportData(prev => ({ ...prev, pending: false }))
        })
    }
  }, [rejectFinalModalData])

  const resetPaginationCb = useCallback(() => {
    setPagination({ offset: 0, limit: 20 })
  }, [pagination])

  const setPaginationCb = useCallback(
    value => {
      setPagination(value)
      setFilterState(prev => ({ ...prev, isActive: true }))
    },
    [filterState]
  )

  const setAddEditModalDataCb = useCallback(
    value => {
      setAddEditModalData(value)
    },
    [addEditModalData]
  )

  const setAddEditModalDataCbEdit = useCallback(
    value => {
      setEditPenaltyModal(true)
      setEditModalData(value)
    },
    [addEditModalData]
  )

  useEffect(() => {
    if (!addPenaltyModal) {
      setAddEditModalData(null)
    }

    if (!editPenaltyModal) {
      setEditModalData(null)
    }
  }, [addPenaltyModal, editPenaltyModal])

  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const toggleFilterCb = useCallback(() => {
    setFilterState({
      ...filterState,
      isOpen: !filterState.isOpen,
      isActive: false
    })
  }, [filterState, filterState.isOpen])

  const toggleMobileFilterCb = useCallback(() => {
    setFilterState({
      ...filterState,
      mobileFilter: !filterState.mobileFilter,
      isActive: false
    })
  }, [filterState, filterState.mobileFilter])

  const closeModalCb = useCallback(() => {
    setEditPenaltyModal(false)
    setAddPenaltyModal(false)
  }, [editPenaltyModal, addPenaltyModal])

  const closeViewModalCb = useCallback(() => {
    setViewPenaltyModal(false)
  }, [viewPenaltyModal])

  const setViewModalDataCb = useCallback(
    value => {
      setViewPenaltyModal(true)
      setViewModalData(value)
    },
    [viewPenaltyModal]
  )

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={SUPPORT_PENALTY_ADMIN_FILTER}
            filterState={filterState}
            statusesDict={
              dict?.statuses.map(x => ({
                id: x.id,
                name: x.value
              })) as StatusesDict
            }
            setFilterState={setFilterStateCb}
            resetPagination={resetPaginationCb}
            filters={[
              Filters.hallPicker,
              Filters.statusesCheckBox,
              Filters.searchInput,
              Filters.rangePicker
            ]}
            filterText={adminPenaltyText.filter}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={toggleFilterCb}
        toggleMobileFilter={toggleMobileFilterCb}
        pageContent={
          <div>
            <Title
              titleText={adminPenaltyText.title}
              tooltipText={adminPenaltyText.tooltip}
              rightAdditionalCmp={
                <ButtonPrimary onClick={() => setAddPenaltyModal(true)}>
                  {adminPenaltyText.addPenaltyButton}
                </ButtonPrimary>
              }
              rightAdditionalClassName={"ProposalNewButtonTitleContainer"}
              bottomAdditionalCmp={
                <div className="UserPenaltySubtitleRange">
                  {getDateDescriptionLayout({
                    dates:
                      filterState.data.dates?.from && filterState.data.dates?.to
                        ? [
                            filterState.data.dates?.from,
                            filterState.data.dates?.to
                          ]
                        : null,
                    isShift: false,
                    show: true,
                    shift: [8, 20],
                    hideTime: true,
                    className:
                      "UserPenaltySubtitleRangePeriod AdminPenaltySubtitleRangePeriod"
                  })}
                </div>
              }
              wrapperClass="PenaltyTitleWrapper"
            />
            {(reportData?.pending || !!reportData?.data.length) && (
              <>
                <div className="AdminPenaltyCardsWrapper">
                  {reportData?.data.map(penalty => (
                    <AdminPenaltyCard
                      key={penalty.id}
                      penalty={penalty}
                      statuses={dict.statuses}
                      winDict={dict.win}
                      setViewModalData={setViewModalDataCb}
                      setEditFinalModal={val => {
                        setEditFinalModal(val)
                      }}
                      setCancelFinalModal={() => setCancelFinalModal(true)}
                      setEditModalDataCb={setAddEditModalDataCbEdit}
                    />
                  ))}
                </div>
                <PaginationComponent
                  value={pagination}
                  onChange={setPaginationCb}
                  pageSize={20}
                  total={reportData.total}
                />
              </>
            )}
            {!reportData.pending && !reportData.data?.length && (
              <NotifiEmptyTable />
            )}
          </div>
        }
        modalCmp={
          <>
            <EditCancelRejectFinalModal
              edit={editFinalModal}
              cancel={cancelFinalModal}
              reject={rejectFinalModal}
              setEditFinalModalData={setEditFinalModalData}
              setCancelFinalModalData={setCancelFinalModalData}
              setRejectFinalModalData={setRejectFinalModalData}
              closeEditFinalModal={() => setEditFinalModal(false)}
              closeCancelFinalModal={() => setCancelFinalModal(false)}
              closeRejectFinalModal={() => setRejectFinalModal(false)}
              id={viewModalData?.id}
              loading={reportData.pending}
            />
            <AddEditPenaltyModal
              newModal={addPenaltyModal}
              editModal={editPenaltyModal}
              loading={reportData.pending}
              reasonOptions={dict.reasons}
              winOptions={dict.win}
              closeModal={closeModalCb}
              setModalDataCb={setAddEditModalDataCb}
              editModalData={editModalData}
              setEditFinalModal={() => setEditFinalModal(true)}
              setEditFinalModalData={(val: string) =>
                setEditFinalModalData(val)
              }
              setEditModalData={val => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setEditModalData({ ...editModalData, ...val })
              }}
            />
            <ViewPenaltyModal
              closeModal={closeViewModalCb}
              visible={viewPenaltyModal}
              viewModalData={viewModalData}
              statuses={dict.statuses}
              setEditModalDataCb={setAddEditModalDataCbEdit}
              setCancelFinalModal={() => setCancelFinalModal(true)}
              setEditFinalModal={() => setEditFinalModal(true)}
              setRejectFinalModal={() => setRejectFinalModal(true)}
              setAppealModal={() => setAppealModal(true)}
              winDict={dict.win}
            />
            <AppealModal
              appealModal={appealModal}
              winOptions={dict.win}
              win={viewModalData?.win}
              amount={viewModalData?.penaltyValue}
              closeAppealModal={() => setAppealModal(false)}
              setAppealModalData={(val: AppealModalData) =>
                setAppealModalData(val)
              }
              loading={reportData.pending}
            />
          </>
        }
      />
    </>
  )
}
