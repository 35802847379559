import React from "react"
import { useRequest } from "api/hooks"
import { StatusLabelProps, UsePenaltyRequests } from "./types"
import { parseDateToBackNewConventions } from "constants/index"

export const SUPPORT_PENALTY_USER_FILTER = "SUPPORT_PENALTY_USER_FILTER"
export const TOTAL_COUNT_HEADER_KEY = "total"
const SUPPORT_PENALTY_USER_BASE_URL_API = "v1/user/penalty"
const SUPPORT_PENALTY_USER_DICT_URL_API = "v1/penalty/dict"
const SUPPORT_PENALTY_USER_RULES_URL_API = "v1/user/penalty/appeal/rules"

export const userPenaltyText = {
  title: "Штрафы",
  tooltip:
    "Раздел предназначен для оповещения и хранения информации о штрафах, наложенных в рамках штрафной сетки принятой в компании. Присутствует возможность поиска по номеру клуба, статусу штрафа и отображение штрафов только за необходимый период. Также раздел позволяет однократно оставить запрос на обжалование штрафа в течение 14 рабочих дней при условии веской аргументации для рассмотрения запроса.",
  period: "За период",
  addPenaltyButton: "Добавить штраф",
  filter: {
    statusesCheckBoxFilter: { header: "Статус штрафа" },
    searchInputFilter: {
      header: "Причина штрафа",
      placeholder: "Введите название"
    },
    rangePickerFilter: { header: "Период штрафа" }
  },
  total: "Всего",
  penalty: "штраф",
  penaltySec: "штрафа",
  penaltyThird: "штрафов"
}

const statusClassesMap: { [key: number]: string } = {
  1: "PenaltyStatusRed PenaltyStatusNew",
  2: "PenaltyStatusGrey PenaltyStatusView",
  3: "PenaltyStatusGrey PenaltyStatusCons",
  4: "PenaltyStatusGrey PenaltyStatusReviewed",
  5: "PenaltyStatusRed PenaltyStatusReqCancel",
  6: "PenaltyStatusGreen PenaltyStatusCancel"
}

export const StatusLabel: React.FC<StatusLabelProps> = ({ statuses, id }) => {
  const validStatus = statuses.find(x => x.id === id)
  return (
    <div
      className={`PenaltyStatus ${validStatus &&
        statusClassesMap[validStatus.id]}`}
    >
      {validStatus?.value}
    </div>
  )
}

export const usePenaltyRequests = ({
  filterState,
  pagination,
  appealModalData,
  viewId
}: UsePenaltyRequests) => {
  const { request: getPenaltyList } = useRequest({
    url: `${SUPPORT_PENALTY_USER_BASE_URL_API}/list`,
    method: "POST",
    requestBody: {
      ...filterState.data,
      dates:
        filterState.data.dates?.from && filterState.data.dates?.to
          ? parseDateToBackNewConventions({
              date: [filterState.data.dates?.from, filterState.data.dates?.to],
              gmt: "3",
              isObj: true
            })
          : undefined,
      ...pagination
    }
  })

  const { request: getPenaltyDict } = useRequest({
    url: SUPPORT_PENALTY_USER_DICT_URL_API,
    method: "GET"
  })

  const { request: getPenaltyRules } = useRequest({
    url: SUPPORT_PENALTY_USER_RULES_URL_API,
    method: "GET"
  })

  const { request: appealPenalty } = useRequest({
    url: `${SUPPORT_PENALTY_USER_BASE_URL_API}/appeal`,
    method: "POST",
    requestBody: appealModalData
  })

  const { request: viewPenalty } = useRequest({
    url: `${SUPPORT_PENALTY_USER_BASE_URL_API}/${viewId}`,
    method: "GET"
  })

  return {
    getPenaltyDict,
    getPenaltyList,
    appealPenalty,
    viewPenalty,
    getPenaltyRules
  }
}
