import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useRequestBody } from "api/hooks"
import ReportWithFilter from "Components/ReportWithFilter"
import SideFilter from "Components/SideFilter"
import { Filters } from "Components/SideFilter/types"
import Title from "Components/Title"
import PaginationComponent from "Components/Pagination"
import NotifiEmptyTable from "Containers/Tools/Notifications/Table/EmptyTable"
import { SUPPORT_PENALTY_USER_FILTER } from "./helper"
import {
  userPenaltyText,
  TOTAL_COUNT_HEADER_KEY,
  usePenaltyRequests
} from "./helper"
import {
  AdminPenaltyData,
  AdminPenaltyFilter,
  AppealRules,
  Dict,
  PenaltyDataListResponse,
  ReportDataState
} from "./types"
import { StatusesDict } from "../../Proposal/types"
import UserPenaltyCard from "./UserPenaltyCard"
import ViewPenaltyModal from "./ViewPenaltyModal"
import AppealModal from "./AppealModal"
import { AppealModalData } from "./AppealModal/types"
import { getDateDescriptionLayout } from "constants/index"
import plural from "plural-ru"
import {
  getInitialFilterState,
  PenaltyStatuses,
  statusReadableLabels,
  winEmpty
} from "../helpers"

const initialReportData: ReportDataState = {
  data: [],
  total: 0,
  pending: false
}

export const UserPenalty: React.FC = () => {
  const [dict, setDict] = useState<Dict>({
    reasons: [],
    statuses: [],
    win: []
  })
  const { pagination, setPagination } = useRequestBody(20)
  const initFilterState = getInitialFilterState({
    storage: SUPPORT_PENALTY_USER_FILTER
  })

  const [initDataRequested, setInitDataRequested] = useState(false)
  const [filterState, setFilterState] = useState<AdminPenaltyFilter>(
    initFilterState
  )
  const [reportData, setReportData] = useState<ReportDataState>(
    initialReportData
  )
  const [appealModal, setAppealModal] = useState(false)
  const [viewPenaltyModal, setViewPenaltyModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [viewModalData, setViewModalData] = useState<AdminPenaltyData | null>(
    null
  )
  const [
    appealModalData,
    setAppealModalData
  ] = useState<AppealModalData | null>(null)
  const [appealRules, setAppealRules] = useState<AppealRules>({
    name: "",
    url: ""
  })

  const successTimerRef = useRef<NodeJS.Timeout | null>(null)
  const isMobile = window.innerWidth < 921
  const pluralDatesText = useMemo(() => {
    return plural(
      reportData.total,
      userPenaltyText.penalty,
      userPenaltyText.penaltySec,
      userPenaltyText.penaltyThird
    )
  }, [reportData.total])

  const {
    getPenaltyDict,
    getPenaltyList,
    appealPenalty,
    viewPenalty,
    getPenaltyRules
  } = usePenaltyRequests({
    filterState,
    pagination,
    appealModalData: {
      id: viewModalData?.id,
      name: appealModalData?.name,
      comment: appealModalData?.comment,
      attachments: appealModalData?.attachments
    },
    viewId: viewModalData?.id
  })

  useEffect(() => {
    getPenaltyRules().then(res => setAppealRules({ ...res.data }))
  }, [])

  const setNextReportDataList = (response: PenaltyDataListResponse) => {
    setReportData(prev => ({
      ...prev,
      data: response.data,
      total: response.headers[TOTAL_COUNT_HEADER_KEY],
      pending: false
    }))
  }

  useEffect(() => {
    if (filterState.isActive || !initDataRequested) {
      setReportData(prev => ({ ...prev, pending: true }))
      getPenaltyList().then(resp => {
        setNextReportDataList(resp)
        setInitDataRequested(true)
      })
      setInitDataRequested(true)
    }
  }, [filterState])

  useEffect(() => {
    getPenaltyDict().then(res =>
      setDict({
        ...res.data,
        statuses: statusReadableLabels(res.data.statuses),
        win: [winEmpty, ...res.data.win]
      })
    )
  }, [])

  const clearTimer = (timer: NodeJS.Timeout | null) => {
    if (timer) {
      clearTimeout(timer)
    }
  }

  useEffect(() => {
    if (appealModalData) {
      clearTimer(successTimerRef.current)
      setReportData(prev => ({ ...prev, pending: true }))
      appealPenalty()
        .then(() => {
          getPenaltyList()
            .then(res => {
              setSuccessModal(true)
              successTimerRef.current = setTimeout(() => {
                setSuccessModal(false)
                setNextReportDataList(res)
                setAppealModal(false)
                setViewPenaltyModal(false)
                setViewModalData(null)
                setAppealModalData(null)
              }, 1500)
            })
            .catch(() => {
              setReportData(prev => ({ ...prev, pending: false }))
              setSuccessModal(false)
            })
        })
        .catch(() => {
          setReportData(prev => ({ ...prev, pending: false }))
          setSuccessModal(false)
        })
    }
  }, [appealModalData])

  useEffect(() => {
    if (viewModalData && viewModalData.status === PenaltyStatuses.new) {
      viewPenalty().then(res => {
        setReportData(prev => {
          const idx = prev.data.findIndex(el => el.id === viewModalData.id)
          const nextData = [...prev.data]
          if (idx !== -1) {
            nextData[idx] = { ...nextData[idx], status: res.data.status }
          }
          return { ...prev, data: nextData }
        })
      })
    }
  }, [viewModalData])

  const resetPaginationCb = useCallback(() => {
    setPagination({ offset: 0, limit: 20 })
  }, [pagination])

  const setPaginationCb = useCallback(
    value => {
      setPagination(value)
      setFilterState(prev => ({ ...prev, isActive: true }))
    },
    [filterState]
  )

  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const toggleFilterCb = useCallback(() => {
    setFilterState({
      ...filterState,
      isOpen: !filterState.isOpen,
      isActive: false
    })
  }, [filterState, filterState.isOpen])

  const toggleMobileFilterCb = useCallback(() => {
    setFilterState({
      ...filterState,
      mobileFilter: !filterState.mobileFilter,
      isActive: false
    })
  }, [filterState, filterState.mobileFilter])

  const closeViewModalCb = useCallback(() => {
    setViewPenaltyModal(false)
  }, [viewPenaltyModal])

  const setViewModalDataCb = useCallback(
    value => {
      setViewPenaltyModal(true)
      setViewModalData(value)
    },
    [viewPenaltyModal]
  )

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={SUPPORT_PENALTY_USER_FILTER}
            filterState={filterState}
            statusesDict={
              dict?.statuses.map(x => ({
                id: x.id,
                name: x.value
              })) as StatusesDict
            }
            setFilterState={setFilterStateCb}
            resetPagination={resetPaginationCb}
            filters={[
              Filters.hallPicker,
              Filters.statusesCheckBox,
              Filters.rangePicker
            ]}
            filterLabels={{
              RangePicker: "Период"
            }}
            filterText={userPenaltyText.filter}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={toggleFilterCb}
        toggleMobileFilter={toggleMobileFilterCb}
        pageContent={
          <div>
            <Title
              titleText={userPenaltyText.title}
              tooltipText={userPenaltyText.tooltip}
              bottomAdditionalCmp={
                <div
                  className={`UserPenaltySubtitleRange ${
                    isMobile ? "UserPenaltySubtitleRangeMobile" : ""
                  }`}
                >
                  {!isMobile && (
                    <div>{`${userPenaltyText.total} ${reportData.total} ${pluralDatesText}`}</div>
                  )}
                  {getDateDescriptionLayout({
                    dates:
                      filterState.data.dates?.from && filterState.data.dates?.to
                        ? [
                            filterState.data.dates?.from,
                            filterState.data.dates?.to
                          ]
                        : null,
                    isShift: false,
                    show: true,
                    shift: [8, 20],
                    hideTime: true,
                    className: "UserPenaltySubtitleRangePeriod"
                  })}
                </div>
              }
              wrapperClass={`PenaltyTitleWrapper ${
                isMobile ? "TitleWrapperMobile" : ""
              }`}
            />
            {(reportData?.pending || !!reportData?.data.length) && (
              <div>
                <div
                  className={`UserPenaltyCardsWrapper ${
                    isMobile ? "UserPenaltyCardsWrapperMobile" : ""
                  }`}
                >
                  {reportData?.data.map(penalty => (
                    <UserPenaltyCard
                      key={penalty.id}
                      penalty={penalty}
                      statuses={dict.statuses}
                      winDict={dict.win}
                      setViewModalData={setViewModalDataCb}
                    />
                  ))}
                </div>
                <PaginationComponent
                  value={pagination}
                  onChange={setPaginationCb}
                  pageSize={20}
                  total={reportData.total}
                />
              </div>
            )}
            {!reportData.pending && !reportData.data?.length && (
              <NotifiEmptyTable />
            )}
          </div>
        }
        modalCmp={
          <>
            <ViewPenaltyModal
              closeModal={closeViewModalCb}
              visible={viewPenaltyModal}
              viewModalData={viewModalData}
              statuses={dict.statuses}
              setAppealModal={() => setAppealModal(true)}
              winDict={dict.win}
              isMobile={isMobile}
              appealModal={appealModal}
              appealable={viewModalData?.appealable}
            />
            <AppealModal
              appealModal={appealModal}
              winOptions={dict.win}
              win={viewModalData?.win}
              amount={viewModalData?.penaltyValue}
              closeAppealModal={() => setAppealModal(false)}
              viewModalData={viewModalData}
              setAppealModalData={value => setAppealModalData(value)}
              loading={reportData.pending}
              successModal={successModal}
              isMobile={isMobile}
              rules={appealRules}
            />
          </>
        }
      />
    </>
  )
}
