import React, { useState, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

import { useRequest, useRequestParams } from "api/hooks"

import DeleteModal, { ResurrectModal } from "../DeleteModal"

import { SelectComponent } from "Components/Select"
import EditorComponent from "Components/Editor"
import InputComponent from "Components/Input"
import { CheckBoxComponent } from "Components/CheckBox"
import { ButtonPrimary, ButtonNotifi } from "Components/Button"
import FileComponent from "Components/File"
import CalendarPopup from "Components/Calendar"

import HallsCheckComponent from "./HallsCheck"
import SuccessNotifiModal from "./Success"

import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import { ReactComponent as AttachIcon } from "Components/icons/attach.svg"
import { ReactComponent as DeleteIcon } from "Components/icons/delete_red.svg"
import { ReactComponent as EditIcon } from "Components/icons/edit.svg"
import { ReactComponent as ErrorFileIcon } from "Components/icons/error_toast.svg"
import { ReactComponent as SendMobileIcon } from "Components/icons/send_24.svg"

import { Upload, notification, Tooltip, Button, message } from "antd"

import {
  getLocationsList,
  getCityList,
  getRegions
} from "store/dictionary/selector"
import { ReactComponent as CopyIcon } from "Components/icons/copy.svg"

import moment, { Moment } from "moment"

import uniq from "lodash/uniq"
import noop from "lodash/noop"
import { checkFileFormat } from "api/helpers"

import { saveNotifi } from "./helper"

import { CrudDataType, CrudModalProps, NotifiValidateData } from "./types"
import {
  checkValue,
  countryArr,
  getEmptyTextArea,
  getLocationPlaceholder,
  getNecessaryText,
  getTypes,
  typography,
  urls,
  validateInitial
} from "./constants"
import { copyTextToClipboard } from "utils/copy"

type DictionaryItem = {
  value: number
  label: string
}

const CrudModal = ({ type, id, refresh, initial, close }: CrudModalProps) => {
  const { history, logout, developer, addDeveloperLink } = useRequestParams()

  const { isDraftType, isBasketType, isStatisticType, isNew } = getTypes(
    type,
    id
  )

  const { request: getCities } = useRequest({
    url: urls.dictCities,
    method: "POST",
    requestBody: {
      only_has_active_halls: true
    }
  })

  const { request: getHalls } = useRequest({
    url: urls.dictHallsByCities,
    method: "POST",
    requestBody: {
      only_has_active_halls: true
    }
  })

  const [isView, toggleIsView] = useState<boolean>(!isNew && !isDraftType)
  const locations = useSelector(getLocationsList)
  const cityList = useSelector(getCityList)
  const regionList = useSelector(getRegions)

  const countryOptions = (locations.country || [])
    .map(item => ({
      value: item.id,
      label: item.name
    }))
    .filter(item => countryArr.includes(item.label))

  const [notifiData, setNotifiData] = useState<CrudDataType>(initial)
  const [formPending, setPending] = useState<boolean>(false)
  const [editorState, setEditorState] = useState(notifiData.text)

  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [resurrectModal, setResurrectModal] = useState<boolean>(false)

  const [searchCity, setSearchCity] = useState<string | null>(null)
  const [searchRegion, setSearchRegion] = useState<string | null>(null)
  const [foundCitiesByRegion, setFoundCitiesByRegion] = useState<
    Array<{ id: number; name: string }>
  >([])
  const cityOptions = useMemo(() => {
    if (notifiData.region_id.length) {
      return foundCitiesByRegion.map(item => ({
        value: item.id,
        label: item.name
      }))
    }

    return notifiData.country_id.length
      ? notifiData.country_id.map(countryId =>
          locations.city[countryId].map(item => ({
            value: item.id,
            label: item.name
          }))
        )
      : cityList
  }, [notifiData, foundCitiesByRegion])

  const regionOptions = notifiData.country_id.length
    ? notifiData.country_id.map(countryId =>
        locations.region[countryId].map(item => ({
          value: item.id,
          label: item.name
        }))
      )
    : regionList

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line prefer-spread
  const mergedCity = [].concat.apply([], cityOptions) || []

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line prefer-spread
  const mergedRegion = [].concat.apply([], regionOptions) || []

  const searchOptions = (mergedCity || []).filter((item: any) =>
    item.label.toLowerCase().includes((searchCity || ``).toLowerCase())
  )

  const searchRegionOptions = (mergedRegion || []).filter((item: any) =>
    item.label.toLowerCase().includes((searchRegion || ``).toLowerCase())
  )

  const [baseFiles, setBaseFiles] = useState<any[]>([])
  const formDisabled = formPending || isView

  const [disabledSave, setSaveDisabled] = useState<boolean>(false)
  const [successModal, setSuccessModal] = useState<boolean>(false)

  const disabledSendButton =
    disabledSave ||
    (!notifiData.city_id.length &&
      !notifiData.country_id.length &&
      !notifiData.halls.length) ||
    !editorState ||
    !notifiData.title ||
    `${editorState}` ==
      `<p></p>
`
  const showLocationPlaceholder =
    !notifiData.halls.length &&
    !notifiData.city_id.length &&
    !notifiData.country_id.length

  const [validate, setValidate] = useState<NotifiValidateData>(validateInitial)
  useEffect(() => setValidate(validateInitial), [notifiData, editorState])

  const saveHandle = () => {
    if (disabledSendButton) {
      setValidate({
        location: getLocationPlaceholder(showLocationPlaceholder),
        title: getNecessaryText(!notifiData.title),
        text: getEmptyTextArea(editorState)
      })
    } else {
      saveNotifi({
        developer,
        addDeveloperLink,
        logout,
        history,
        id,
        isNew,
        isDraftType,
        data: {
          ...notifiData,
          text: editorState,
          is_draft: isDraftType ? false : notifiData.is_draft
        },
        setPending,
        setSuccessModal
      })
    }
  }

  const copyNumbersOfHalls = (halls: number[]) => {
    copyTextToClipboard(halls.join(",")).then(() => {
      message.success(typography.hallsNumbersCopied)
    })
  }

  const fetchCitiesByRegion = () => {
    getCities({ region_ids: notifiData.region_id }).then(({ data }) =>
      setFoundCitiesByRegion(data.cities)
    )
  }

  const fetchHallsByCities = (city_ids: number[]) => {
    if (!city_ids.length) {
      setNotifiData(prev => ({
        ...prev,
        halls: []
      }))

      return
    }

    getHalls({ city_ids }).then(({ data }) =>
      setNotifiData(prev => ({
        ...prev,
        halls: data.halls
      }))
    )
  }

  useEffect(() => {
    if (notifiData.region_id.length) {
      fetchCitiesByRegion()
    } else {
      setFoundCitiesByRegion([])
    }
  }, [notifiData])

  return (
    <div className="NotifiCrudModalWrapper">
      {!successModal && (
        <div className="NotifiCrudModal">
          <div className="FlexRow">
            <div className="NotifiCrudModalTitle">
              {isNew ? `Новое сообщение` : `Cообщение ${id}`}
            </div>
            <div className="NotifiCrudModalClose" onClick={close}>
              <CloseIcon />
            </div>
          </div>
          <div className="NotifiCrudModalBody">
            <div className="NotifiCrudModalBodyLocationFields NotifiCrudModalBodyHeader">
              <div className="FlexColumn NotifiCrudModalBodyLocationItem MarginRight24">
                <div className="InputLabel">Локация</div>
                <SelectComponent
                  value={notifiData.country_id}
                  onChange={value => {
                    if (checkValue(value)) {
                      setNotifiData({
                        ...notifiData,
                        country_id: value,
                        city_id: []
                      })
                    }
                    if (!value.length)
                      setNotifiData({ ...notifiData, country_id: [] })
                  }}
                  options={countryOptions}
                  disabled={formDisabled}
                  view={isView}
                  placeholder="Не учитывать"
                  allowClear
                  mode="tags"
                  error={validate.location}
                />
              </div>
              <div className="FlexColumn MarginRight24">
                <div className="InputLabel">Регион</div>
                <SelectComponent
                  value={notifiData.region_id}
                  onChange={value => {
                    if (checkValue(value)) {
                      setNotifiData({ ...notifiData, region_id: value })
                    }
                    if (!value.length)
                      setNotifiData({ ...notifiData, region_id: [] })
                    setSearchRegion(null)
                  }}
                  onSearch={value => setSearchRegion(value)}
                  disabled={formDisabled}
                  filterOption={false}
                  view={isView}
                  placeholder="Не учитывать"
                  allowClear
                  mode="multiple"
                  options={searchRegionOptions as DictionaryItem[]}
                  error={validate.location}
                />
              </div>
              <div className="FlexColumn">
                <div className="InputLabel">Город</div>
                <SelectComponent
                  value={notifiData.city_id}
                  onChange={value => {
                    if (checkValue(value)) {
                      setNotifiData({ ...notifiData, city_id: value })
                    }
                    if (!value.length)
                      setNotifiData({ ...notifiData, city_id: [] })

                    setSearchCity(null)
                    fetchHallsByCities(value)
                  }}
                  onSearch={value => setSearchCity(value)}
                  disabled={formDisabled}
                  filterOption={false}
                  view={isView}
                  placeholder="Не учитывать"
                  allowClear
                  mode="multiple"
                  options={searchOptions as DictionaryItem[]}
                  error={validate.location}
                />
              </div>
            </div>
            <div className="NotifiCrudModalBodyContent">
              <div className="FlexColumn NotifiCrudModalBodyContentRow">
                <div className="InputLabel Flex SpaceBetween">
                  Клубы{" "}
                  {!isNew && (
                    <Button
                      className="CopyButton"
                      onClick={() => copyNumbersOfHalls(notifiData.halls)}
                      type="link"
                      icon={<CopyIcon />}
                    >
                      Скопировать
                    </Button>
                  )}
                </div>
                <SelectComponent
                  value={notifiData.halls}
                  disabled={formDisabled}
                  view={isView}
                  onChange={value => {
                    if (
                      notifiData.halls &&
                      notifiData.halls.length < value.length
                    ) {
                      const lastEnter = value
                        .pop()
                        .split(`,`)
                        .filter(
                          (item: string) =>
                            !!Number(item) && Number.isInteger(Number(item))
                        )
                        .map((item: any) => Number(item))
                      const newValue = uniq([...value, ...lastEnter])
                      setNotifiData({ ...notifiData, halls: newValue })
                    } else {
                      setNotifiData({ ...notifiData, halls: value })
                    }
                  }}
                  open={false}
                  placeholder="Введите номера клубов через запятую"
                  allowClear
                  mode="tags"
                  error={validate.location}
                />
              </div>
              {!isView && (
                <HallsCheckComponent
                  city={notifiData.city_id}
                  location={notifiData.country_id}
                  halls={notifiData.halls}
                  setSaveDisabled={setSaveDisabled}
                />
              )}
              <div className="FlexColumn NotifiCrudModalBodyContentRow">
                <div className="InputLabel">Комментарий</div>
                <InputComponent
                  value={notifiData.comment}
                  onChange={({ target: { value } }) =>
                    setNotifiData({ ...notifiData, comment: value })
                  }
                  placeholder="Его увидят только администраторы"
                  allowClear
                  disabled={formDisabled}
                  view={isView}
                  maxLength={150}
                />
              </div>
              <div className="FlexColumn NotifiCrudModalBodyContentRow">
                <div className="InputLabel">Тема сообщения</div>
                <InputComponent
                  value={notifiData.title}
                  onChange={({ target: { value } }) =>
                    setNotifiData({ ...notifiData, title: value })
                  }
                  placeholder="Например «Акция» или «Смс рассылка»"
                  allowClear
                  disabled={formDisabled}
                  view={isView}
                  maxLength={150}
                  error={validate.title}
                />
              </div>
              <div className="FlexColumn NotifiCrudModalBodyContentRow">
                <div className="InputLabel">Текст сообщения</div>
                <div>
                  <EditorComponent
                    disabled={formDisabled}
                    initial={notifiData.text}
                    setFormData={(text: string) => {
                      setEditorState(text)
                    }}
                    error={validate.text}
                  />
                </div>
              </div>
              <div className="NotifiCrudModalBodyContentRow FlexRow">
                <div className="NotifiCrudModalCheckbox">
                  <CheckBoxComponent
                    // key={`hall_value_${item}`}
                    label="Пуш-уведомление"
                    value={notifiData.is_push}
                    checked={notifiData.is_push}
                    onChange={({ target: { checked } }) =>
                      setNotifiData({ ...notifiData, is_push: checked })
                    }
                    disabled={formDisabled}
                  />
                </div>
                <div className="NotifiCrudModalCheckbox">
                  <CheckBoxComponent
                    label="Обязательное"
                    value={notifiData.is_require}
                    checked={notifiData.is_require}
                    onChange={({ target: { checked } }) =>
                      setNotifiData({ ...notifiData, is_require: checked })
                    }
                    disabled={formDisabled}
                  />
                </div>
                {!isDraftType && (
                  <div className="NotifiCrudModalCheckbox">
                    <CheckBoxComponent
                      label="Черновик"
                      value={notifiData.is_draft}
                      checked={notifiData.is_draft}
                      onChange={({ target: { checked } }) =>
                        setNotifiData({ ...notifiData, is_draft: checked })
                      }
                      disabled={formDisabled}
                    />
                  </div>
                )}
              </div>
              {notifiData.files && !!notifiData.files.length && (
                <div className="NotifiCrudModalFiles FlexRow FlexWrap">
                  {notifiData.files.map(
                    (
                      item: {
                        name: string
                        filename: string
                        type: string | undefined
                        uid: string
                        size: number
                        link: string
                      },
                      key: number
                    ) => {
                      return (
                        <FileComponent
                          key={item.uid}
                          view={isView}
                          name={item.name || item.filename}
                          type={item.type}
                          size={item.size}
                          link={item.link}
                          deleteFile={() => {
                            const newArray = notifiData.files
                            newArray.splice(key, 1)
                            setNotifiData({ ...notifiData, files: newArray })
                          }}
                        />
                      )
                    }
                  )}
                </div>
              )}
              {!isStatisticType && isView && (
                <div className="NotifiCrudModalBodyContentButtonsRow FlexRow">
                  <div className="NotifiCrudModalButtons">
                    {isBasketType ? (
                      <ButtonPrimary onClick={() => setResurrectModal(true)}>
                        Восстановить
                      </ButtonPrimary>
                    ) : (
                      <ButtonNotifi onClick={() => toggleIsView(false)}>
                        <div className="FlexRow">
                          <EditIcon />
                          <span>Редактировать</span>
                        </div>
                      </ButtonNotifi>
                    )}
                  </div>
                  <div className="NotifiCrudModalButtons">
                    <ButtonNotifi onClick={() => setDeleteModal(true)}>
                      <div className="FlexRow">
                        <DeleteIcon />
                        <span>Удалить</span>
                      </div>
                    </ButtonNotifi>
                  </div>
                </div>
              )}
              {!isStatisticType && !isView && (
                <div className="NotifiCrudModalBodyContentButtonsRow FlexRow">
                  <div className="NotifiCrudModalButtons NotifiCrudModalSaveButton">
                    <ButtonPrimary disabled={formPending} onClick={saveHandle}>
                      {isNew || isDraftType ? `Отправить` : `Сохранить`}
                    </ButtonPrimary>
                  </div>
                  <div className="NotifiCrudModalButtons NotifiCrudModalMobileSaveButton">
                    <ButtonPrimary onClick={saveHandle}>
                      <SendMobileIcon />
                    </ButtonPrimary>
                  </div>
                  {(isNew ||
                    !!(
                      notifiData.date &&
                      moment(notifiData.date).valueOf() > moment().valueOf()
                    )) && (
                    <div className="NotifiCrudModalButtons">
                      <CalendarPopup
                        date={notifiData.date}
                        setDate={(value: Moment | null) =>
                          setNotifiData({ ...notifiData, date: value })
                        }
                      />
                    </div>
                  )}
                  <div className="NotifiCrudModalButtons">
                    <Tooltip
                      overlayClassName="InfoTooltipWrapper"
                      placement="bottom"
                      title="Добавить вложение"
                      trigger="hover"
                      color="#343A40"
                    >
                      <div className="UploaderBlock">
                        <Upload
                          onChange={({ file }) => {
                            const format = checkFileFormat(file.name)
                            const size =
                              file && file.size && file.size <= 1000000000
                            const typeFile = { ...file, type: format }
                            if (format && size) {
                              if (!!typeFile.name) {
                                setNotifiData({
                                  ...notifiData,
                                  files: [...notifiData.files, typeFile]
                                })
                              }
                              if (typeFile.name) {
                                const reader = new FileReader()
                                reader.onload = e => {
                                  // eslint-disable-next-line no-param-reassign,@typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  typeFile.base64 = e.target.result
                                }
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                reader.readAsDataURL(file.originFileObj)
                                setBaseFiles([...baseFiles, typeFile])
                              }
                            } else if (!format && !!typeFile.name) {
                              notification.error({
                                message: (
                                  <span className="FileErrorToastTitle">
                                    Ошибка загрузки файла
                                  </span>
                                ),
                                description: (
                                  <div className="FileErrorToastTextField">
                                    <span className="FileErrorToastToastText">
                                      Файл некорректного формата.
                                    </span>
                                    <br />
                                    <span className="FileErrorToastToastText">
                                      Загрузите файл одного из следующих
                                      форматов: doc, docx, jpg, jpeg, png, pdf,
                                      xls, xlsx
                                    </span>
                                  </div>
                                ),
                                placement: "topRight",
                                className: "FileErrorToastWrapper",
                                duration: 5,
                                icon: <ErrorFileIcon />,
                                closeIcon: <CloseIcon />
                              })
                            } else if (!size && !!typeFile.name) {
                              notification.error({
                                message: (
                                  <span className="FileErrorToastTitle">
                                    Ошибка загрузки файла
                                  </span>
                                ),
                                description: (
                                  <div className="FileErrorToastTextField">
                                    <span className="FileErrorToastToastText">
                                      Файл слишком много весит.
                                    </span>
                                    <br />
                                    <span className="FileErrorToastToastText">
                                      Загрузите максимум 10 файлов, размер
                                      каждого не должен привышать 100 Мб.
                                    </span>
                                  </div>
                                ),
                                placement: "topRight",
                                className: "FileErrorToastWrapper",
                                duration: 5,
                                icon: <ErrorFileIcon />,
                                closeIcon: <CloseIcon />
                              })
                            }
                          }}
                          customRequest={() => noop}
                          itemRender={() => noop}
                        >
                          <ButtonNotifi
                            disabled={
                              notifiData.files && notifiData.files.length === 10
                            }
                          >
                            <div className="PaddingTop4">
                              <AttachIcon />
                            </div>
                          </ButtonNotifi>
                        </Upload>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {successModal && <SuccessNotifiModal close={close} refresh={refresh} />}
      {resurrectModal && (
        <ResurrectModal
          type={type}
          resurrectId={[Number(id)]}
          successResurrect={() => {
            setResurrectModal(false)
            close()
            refresh()
          }}
          failedResurrect={() => setResurrectModal(false)}
        />
      )}
      {deleteModal && (
        <DeleteModal
          type={type}
          deleteData={[Number(id)]}
          successDelete={() => {
            setDeleteModal(false)
            close()
            refresh()
          }}
          failedDelete={() => setDeleteModal(false)}
        />
      )}
    </div>
  )
}

export default CrudModal
