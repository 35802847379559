import { CardState } from "./types"

export const validatePhotos = (
  minPhotos: number,
  photoArr: CardState[]
): boolean => {
  let countPhotos = 0
  photoArr.map(photo => {
    if (!!photo.image || !!photo.link) {
      countPhotos += 1
    }
  })
  return countPhotos >= minPhotos
}

// Сравнивает исходный массив с карточками с массивом после изменения
export const compareArrays = (
  arr1: CardState[],
  arr2: CardState[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].link !== arr2[i].link) {
      return false
    }
    if (arr1[i].image !== arr2[i].image) {
      return false
    }

    if (arr1[i].zone !== arr2[i].zone) {
      return false
    }
  }

  return true

  const compareObjects = (obj1: CardState, obj2: CardState) => {
    const keys1 = Object.keys(obj1).sort()
    const keys2 = Object.keys(obj2).sort()

    if (keys1.length !== keys2.length) {
      return false
    }

    for (let i = 0; i < keys1.length; i++) {
      const key1 = keys1[i]
      const key2 = keys2[i]
      const value1 = obj1[key1 as keyof CardState]
      const value2 = obj2[key2 as keyof CardState]

      if (key1 !== key2 || value1 !== value2) {
        return false
      }
    }
    return true
  }

  arr1.sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1))
  arr2.sort((a, b) => (JSON.stringify(a) > JSON.stringify(b) ? 1 : -1))

  for (let i = 0; i < arr1.length; i++) {
    if (!compareObjects(arr1[i], arr2[i])) {
      return false
    }
  }

  return true
}
